import React, {useState} from 'react';
import {Button} from 'react-bootstrap';

import categories from './UseCases.json';
import CategoryTab from '../../components/CategoryTab/CategoryTab';
import useCheckIsMobileScreen from '../../hooks/checkMobileScreen';
import {getExamplesSlug} from '../../Examples/Examples';

import styles from './UseCase.module.css';

const highlightMiddle = (text) => {
  const words = text.split(' ');
  const middleStart = Math.floor(words.length / 4);
  const middleEnd = Math.ceil((3 * words.length) / 4);

  const before = words.slice(0, middleStart).join(' ') + ' ';
  const middle = words.slice(middleStart, middleEnd).join(' ') + ' ';
  const after = words.slice(middleEnd).join(' ') + ' ';

  return {
    before,
    middle,
    after
  };
};

const UseCase = ({history}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const isMobile = useCheckIsMobileScreen();

  const handleClick = (index, label) => {
    setActiveIndex(index);

    if (isMobile) {
      history.push(`/examples/${getExamplesSlug(label)}`);
    }
  };

  const {desc, quote, quoteAuthor, label, img} = categories[activeIndex];
  const {before, middle, after} = highlightMiddle(desc);

  return (
    <div className={styles.page}>
      <div className={styles.tabsContainer}>
        <div className={styles.gradientTextContainer}>
          Yes, tiiny is for you
        </div>
        <div className={styles.tabs}>
          {categories.map((category, index) => {
            return (
              <CategoryTab
                text={category.label}
                icon={category.icon}
                key={index}
                index={index}
                activeIndex={activeIndex}
                onClick={() => handleClick(index, label)}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.contentInfo}>
          <div className="flex-grow-1">
            <h3 className={styles.title}>
              {before}
              {middle}
              {after}
            </h3>
            <Button className={styles.button} onClick={() => history.push(`/examples/${getExamplesSlug(label)}`, { preventScroll: true })}>
              See examples here &rarr;
            </Button>
          </div>
          <div className={styles.quote}>
            <h3 className={styles.quoteText}>"{quote}"</h3>
            <span className={styles.quoteAuthor}> - {quoteAuthor}</span>
          </div>
        </div>
        <div className={styles.contentImage}>
          <img src={`/assets/img/${img}`} alt="example" className={styles.image}/>
        </div>
      </div>
    </div>
  );
};

export default UseCase;
